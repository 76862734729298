<template>
  <div class="hello">
    <img src="@/assets/111.jpg" alt="玉牌、生活用纸、商用纸、吸水衬纸、大轴纸、工业擦拭纸、奶牛擦拭纸、奶羊擦拭纸、医疗用纸、B超纸、定制擦拭纸、吸油纸、无尘纸等">
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
 
}
</script>


<style scoped>
 img{
   width:100%;
 }
</style>
